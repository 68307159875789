/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


        // init Masonry
        var $grid = $('.grid').masonry({
          itemSelector: '.grid-item',
          columnWidth: '.grid-sizer',
          percentPosition: true
        });
        // layout Masonry after each image loads
        $grid.imagesLoaded().progress( function() {
          $grid.masonry('layout');
        });


        $(".full-link").click(function() {
          window.location = $(this).data("url");
          return false;
        });


        // JavaScript to be fired on all pages
        //$(".archive-categories ul").tinyNav();
        $('#search-btn').click(function(){
          $( ".search-container" ).stop().fadeIn( "fast", function() {
            $('#full-search').focus();
          });
        });

        $('.search-container__close-button').click(function(){
          $( ".search-container" ).stop().fadeOut( "fast", function() {
          });
        });

        $(document).keyup(function(e) {
          // On ESC key close search section and blur input
          if(e.keyCode === 27) {
            $( ".search-container" ).stop().fadeOut( "fast", function() {
            });
          }

        });


        $(document).mouseup(function (e)
        {
            var container = $(".search-container__content");

            if (!container.is(e.target) && container.has(e.target).length === 0) // ... nor a descendant of the container
            {
                $( ".search-container" ).stop().fadeOut("fast");
            }
        });


        $("#main-menu").mmenu({
            navbar:{
                title: false,
            },
             offCanvas: {
                position  : "left"
             }
        });
        $(".video-block").fitVids();
        function stop(){
            $('.sub-menu').stop(true, true);
        }
        $( '.menu-item-has-children' ).hover(
            function(){
                $(this).children('.sub-menu').slideDown(300, stop());
            },
            function(){
                $(this).children('.sub-menu').slideUp(300, stop());
            }
        );
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('.flexslider').flexslider({
            animation: "slide",
            smoothHeight: true,
            controlNav: false,
            slideshow: false
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      function sticktothetop() {
          var window_top = $(window).scrollTop();
          var top = $('.scroll-btn').offset().top;
          if (window_top > top) {
              $('body').addClass('stick');
          } else {
              $('body').removeClass('stick');
          }
      }
      $(function() {
          $(window).scroll(sticktothetop);
          sticktothetop();
      });

      },
      finalize: function() {
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
